import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { WEBSITE_NAME } from './config';

const usePageMeta = () => {
  const location = useLocation();

  useEffect(() => {
    const setMetaTags = () => {
      let title;
      let description;
      let url = window.location.href;

      switch (location.pathname) {
        case '/':
          title = `Home Page - Custom Logo Design for Business by ${WEBSITE_NAME}`;
          description = `Welcome to the Home Page of ${WEBSITE_NAME}. We provide custom logo design services for businesses.`;
          break;
        case `/thank-you`:
          title = `Thank You - Custom Logo Design for Business by ${WEBSITE_NAME}`;
          description = `Thank you for visiting ${WEBSITE_NAME}. We appreciate your business.`;
          break;
        case `/lp/`:
          title = `Custom Logo Design for Business by ${WEBSITE_NAME}`;
          description = `The perfect logo design from a perfect logo design company is all you need to start a business. Your company’s logo design and branding are your identities. `;
          break;
        default:
          title = `Custom Logo Design for Business by ${WEBSITE_NAME}`;
          description = `Discover custom logo design services for businesses at ${WEBSITE_NAME}.`;
      }

      document.title = title;

      return (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
        </Helmet>
      );
    };

    setMetaTags();
  }, [location.pathname]);

  return null;
};

export default usePageMeta;
