import React, { useEffect, useState } from 'react';
import { Header } from '../../../layout/header';
import { Footer } from '../../../layout/footer';
import { WEBSITE_NAME, PHONE, PHONE_HREF, EMAIL_HREF, EMAIL } from '../../../config';

export const Privacy = () => {
  return (
    <div>
      <Header />
      <section class="main-privacy">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 privacy">
                    <h4>About The Policy</h4>
                    <p>At {WEBSITE_NAME} the privacy of our clients is our top priority on <a href="javascript:;">www.thedesignverse.com</a>
                        and we
                        respect it as our own. Though we collect information from our clients, it is only used to make
                        improvements in our customer services. Our company acknowledges that the maintenance and use of
                        our
                        clients' information is our responsibility. We DO NOT rent or sell the information that our
                        clients
                        provide us online.</p>
                    <p>This policy describes how the personal information of our client collected by us is used, why we
                        collect
                        it, and how we use it. It is within our policy that we describe the choices you can make about
                        how we
                        can collect and use your information.</p>
                    <h4>Personal Information Collected</h4>
                    <p>The information collected by {WEBSITE_NAME} includes the client's name, e-mail, mailing
                        address and
                        phone number. These are pieces of information that the client provides us while ordering or
                        while saving
                        the information with our company. We may also use the email addresses or mailing addresses which
                        we
                        receive through our mailing system such as our Contact Us Form for responding to comments,
                        queries etc.
                    </p>
                    <p>Our company also maintains records of the items, which have interested our clients in the past,
                        as well
                        as the client's purchases online.</p>
                    <h4>Use of Collected Data</h4>
                    <p>The information collected is used in many diversified methods. Our company uses the information
                        saved by
                        our clients to process their order. We also send them e-mails to confirm the order and our
                        customer
                        services may also contact them via phone, mailing address or e-mail if our company has other
                        queries
                        regarding the order placed.</p>
                    <p>As a client, one might also receive updates regarding our site and services which may include a
                        newsletter and information on promotions. In addition, we may also use the information about
                        your
                        interests and purchases to help our company improve our site design and the client's purchasing
                        experience.
                    </p>
                    <h4>Newsletter Opt-out</h4>
                    <p>If you no longer wish to receive our newsletter and promotional communications, you may opt-out
                        of
                        receiving them by following the instructions included in each newsletter or communication or by
                        emailing
                        us at Or calling us at <a href={PHONE_HREF}>{PHONE}</a> </p>
                    <h4>Social Media (Features) and Widgets</h4>
                    <p>Our Web site includes Social Media Features, such as the Facebook Like button [and Widgets, such
                        as the
                        Share this button or interactive mini-programs that run on our site]. These Features may collect
                        your IP
                        address, which page you are visiting on our site, and may set a cookie to enable the Feature to
                        function
                        properly. Social Media Features and Widgets are either hosted by a third party or hosted
                        directly on our
                        Site. Your interactions with these Features are governed by the privacy policy of the company
                        providing
                        it.</p>
                    <h4>3rd Party Sharing</h4>
                    <p>Personal information will NOT be released to third parties unless as described in this policy.
                        There are
                        no circumstances under which we sell personal information to third parties.</p>
                    <p>We use credit card processing companies to bill you for services. These companies do not retain,
                        share,
                        store or use personally identifiable information for any other purposes. We also use Live Person
                        to
                        provide live customer support chat on our website. These companies are authorized to use your
                        personal
                        information only as necessary to provide these services to us.</p>
                    <h4>Security of Personal Information</h4>
                    <p>The information of our clients is secure, as it is protected during transmission by the use of
                        the Secure
                        Sockets Layer (SSL) Software which encrypts the information the client puts in.
                    </p>
                    <p>
                        We follow generally accepted industry standards to protect the personal information submitted to
                        us,
                        both during transmission and once we receive it. No method of transmission over the Internet, or
                        method
                        of electronic storage, is 100% secure, however. Therefore, while we strive to use commercially
                        acceptable means to protect your personal information, we cannot guarantee its absolute
                        security.
                    </p>
                    <p>If you have any questions about security on our Web site, you can send email us at <a
                            href={EMAIL_HREF}>{EMAIL} </a></p>
                    <h4>Access of Registered Accounts</h4>
                    <p>The client can access their registered accounts by signing in on our homepage. Once signed in,
                        the client
                        has access to their previous lists and information that they have already submitted to the site.
                        The
                        users are able to update their information once they have logged in to their accounts by
                        clicking My
                        Account.</p>
                    <h4>Changing & Deleting/Unsubscribing Accounts</h4>
                    <p>The client has to send in an email to request the cancellation of subscription or request to have
                        their
                        personal information deleted. This shall prevent the user from receiving e-mails communication
                        relating
                        to any online order they might have placed at the site.
                        We will retain your information for as long as your account is active or as needed to provide
                        you
                        services. Please contact us if you wish to cancel your account or request that we no longer use
                        your
                        information to provide you services. We will retain and use your information as necessary to
                        comply with
                        our legal obligations, resolve disputes, and enforce our agreements.
                    </p>
                    <h4>Cookies and Their Use</h4>
                    <p>Cookies are alphanumeric identifiers that are transferred to the clients' hard drives through
                        their Web
                        browsers. This enables our systems to recognize the clients' browsers and storage of items in
                        their
                        Shopping Carts during visits.</p>
                    <p>The Help portion of the toolbar on most browsers will be more helpful in explaining how to
                        prevent the
                        browser from accepting new cookies, how to have the browser notify the user when a new cookie is
                        received, or how to disable cookies altogether. However, cookies allow you to take full
                        advantage of the
                        top features at our site, and our company's personal recommendation is that the client leaves
                        them to be
                        accepted.</p>
                    <p>Second, we keep track of your IP address to help diagnose problems with our server and to
                        administer our
                        Web site. Your IP address is also used to gather broad demographic information about you, such
                        as your
                        location and your Internet service provider. We may also collect combined information on how our
                        users
                        are utilizing the site. This might include information regarding traffic patterns through the
                        site and
                        search queries. No IP address/log file information is tied to Personally Identifiable
                        Information (PII).
                    </p>
                    <p>Third, we log browser types, access times, URLs from which visitors came to our site and URLs
                        viewed by
                        visitors while on our site. Except as otherwise stated in this Privacy Policy, we do not provide
                        this
                        information to third parties, except in combined form.</p>
                    <p>The use of cookies by our partners, affiliates, tracking utility company, service providers is
                        not
                        covered by our privacy statement. We do not have access or control over these cookies. Our
                        partners,
                        affiliates, tracking utility company, service providers use session ID cookies to make it easier
                        for you
                        to navigate our site.</p>
                    <h4>Clear Gifs (Web Beacons/Web Bugs)</h4>
                    <p>We employ a software technology called clear gifs (a.k.a. Web Beacons/Web Bugs), that help us
                        better
                        manage content on our site by informing us what content is effective. Clear gifs are tiny
                        graphics with
                        a unique identifier, similar in function to cookies, and are used to track the online movements
                        of Web
                        users. In contrast to cookies, which are stored on a user's computer hard drive, clear gifs are
                        embedded
                        invisibly on Web pages and are about the size of the period at the end of this sentence. We do
                        not tie
                        the information gathered by clear gifs to our customers' personally identifiable information.
                    </p>
                    <h4>Testimonials</h4>
                    <p>With your consent we may post your testimonial along with your name. If you want your testimonial
                        removed
                        please contact us.</p>
                    <h4>Links to Other Web Sites</h4>
                    <p>Our Site includes links to other Web sites whose privacy practices may differ from those of ours.
                        If you
                        submit personal information to any of those sites, your information is governed by their privacy
                        statements. We encourage you to carefully read the privacy statement of any Web site you visit.
                    </p>
                    <h4>Notification of the Changes in Privacy Policy</h4>
                    <p>If we decide to change our privacy policy, we will post those changes to this privacy statement,
                        the
                        homepage, and other places we deem appropriate so that you are aware of what information we
                        collect, how
                        we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify
                        this
                        privacy statement at any time, so please review it frequently. If we make material changes to
                        this
                        policy, we will notify you here, by email, or by means of a notice on our homepage before the
                        change
                        takes effect.</p>
                    <h4>Legal Disclaimer</h4>
                    <p>We reserve the right to disclose your personally identifiable information as required by law and
                        when we
                        believe that disclosure is necessary to protect our rights and/or comply with a judicial
                        proceeding,
                        court order, or legal process served on our Web site.</p>
                    <h4>Questions</h4>
                    <p>On the off chance that you have any inquiries with respect to our Privacy Policy or our
                        utilization of
                        your data, call our toll free number <a
                            href={PHONE_HREF}>({PHONE})</a> or
                        email us on <a href={EMAIL_HREF}>({EMAIL})</a></p>
                    <p>In order to ensure the privacy of our valued customer we never store any sort of information
                        related to
                        our clients neither we do take any cards information from client.</p>
                    <h3>Consumer Data Safety Measures</h3>
                    <p>We respect your right of privacy. We therefore do not share your name and contact details with
                        any third
                        party. Besides, we keep information provided by you as confidential. The information you provide
                        are
                        used only to understand your requirements and to improve our services to suit your needs rather
                        effectively. We ensure to follow the standards set by PCI and consumer data protection.</p>
                    <p>It is hereby declared that our company does not authorize its representatives to collect any
                        confidential
                        information from our customers i.e. credit card information etc. Accordingly, we advise our
                        customers
                        not to share any confidential and sensitive nature information with our employees. If you do so;
                        you
                        shall be doing at your own risk and our company shall not be liable for any misuse.</p>
                    <p>Your ordered work may be forwarded to any of our production or service centers located globally,
                        for the
                        quality delivery. However, they are also bound by an NDA to keep customer’s information as
                        highly
                        confidential.</p>
                    <h3>Pseudonym Policy</h3>
                    <p>We hold a policy of using the pseudonym to ensure the following:
                        With a view to avoid undue confusion and hassle at our customers end when they are assigned a
                        new
                        account manager, we use pseudonyms; it helps our customers remain connected with a single point
                        of
                        contact and remember their name comfortably.
                    </p>
                    <p>Moreover, as our staff comprises of people from different regions and cultures, hence the use of
                        pseudonym helps us present a uniform culture of the organization</p>
                    <h3>Email Address & Mobile Number Collection</h3>
                    <h3>1. Introduction</h3>
                    <p>This Privacy Policy explains how {WEBSITE_NAME} collects, uses, and protects the email
                        addresses and
                        mobile numbers provided by users on our website. We are committed to ensuring that your privacy
                        is
                        protected and that we comply with all relevant data protection laws.</p>
                    <h3>2. Information We Collect</h3>
                    <p>We may collect the following information:</p>
                    <ul class="addons-list sty4">
                        <li>Mobile phone numbers </li>
                        <li>Any additional information provided voluntarily by the user.</li>
                    </ul>
                    <h3>3. How We Use the Information</h3>
                    <p>We use the collected mobile numbers for the following purposes:</p>
                    <ul class="addons-list sty4">
                        <li>To send relevant updates related to our services. </li>
                        <li>To notify users about account-related information.</li>
                        <li>For internal record keeping.</li>
                    </ul>
                    <h3>4. Consent for Mobile Number Collection</h3>
                    <p>By providing your mobile number on {WEBSITE_NAME} , you consent to the collection and use of
                        this
                        information as outlined in this Privacy Policy.</p>
                    <h3>5. Security</h3>
                    <p>We are committed to ensuring that your information is secure. To prevent unauthorized access or
                        disclosure, we have put in place suitable physical, electronic, and managerial procedures to
                        safeguard
                        and secure the information we collect online.</p>
                    <h3>6. Controlling Your Personal Information</h3>
                    <p>You may choose to restrict the collection or use of your mobile number in the following ways:</p>
                    <ul class="addons-list sty4">
                        <li>If you have previously agreed to us using your mobile number for direct marketing purposes,
                            you may
                            change your mind at any time by contacting us at <a
                                href={EMAIL_HREF}>{EMAIL}</a> or you can reply “STOP” or
                            “Unsubscribe”
                            to stop receiving the messages</li>
                    </ul>
                    <h3>7. Sharing Your Information</h3>
                    <p>We will not sell, distribute, or lease your personal information to third parties unless we have
                        your
                        permission or are required by law to do so.</p>
                    <h3>8. Your Rights</h3>
                    <p>You have the right to request details of the personal information we hold about you. If you would
                        like a
                        copy of this information, please contact us at <a
                            href={EMAIL_HREF}>{EMAIL}</a></p>
                    <h3>9. Changes to this Policy</h3>
                    <p>{WEBSITE_NAME} may change this policy from time to time by updating this page. You should
                        check this
                        page periodically to ensure that you are comfortable with any changes.</p>
                </div>
            </div>
        </div>
    </section>
      <Footer />
    </div>
  );
};
