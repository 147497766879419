import React, { useState } from 'react';
import {
  bannerMain,
  bannerMain_02,
  bannerMain_03,
  bnnrMobImg,
  logoGif,
} from '../../assest';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const Banner = () => {
  const naviagte = useNavigate();
  const [loading, setLoading] = useState(false);

  const [countstep, setCountStep] = useState(0);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const handleNextClick = () => {
    // setCountStep((prevStep) => (prevStep < 2 ? prevStep + 1 : prevStep));
    let canProceed = false;

    switch (countstep) {
      case 0:
        canProceed = formData.name.trim() !== '';
        break;
      case 1:
        canProceed = formData.email.trim() !== '';
        break;
      case 2:
        canProceed = formData.phone.trim() !== '';
        break;
      default:
        canProceed = true;
    }

    if (canProceed) {
      setCountStep((prevStep) => (prevStep < 2 ? prevStep + 1 : prevStep));
    } else {
      if (countstep == 0) {
        alert('Please fill this name required field.');
      } else if (countstep == 1) {
        alert('Please fill this email required field.');
      } else {
        alert('Please fill this phone required field.');
      }
    }
  };

  const handlePrevClick = () => {
    setCountStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;

      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "thedesignverse.com"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      // console.log("baseUrl",baseUrl);

      // Log the URL to the console (optional)
      console.log(currentUrl);

      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
        // lead_url: baseUrl,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=934758';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          console.log('response', response.data.data);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          //  console.log(`/thank-you?${queryString}`);
          // console.log(response.data.data.id);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            phone: '',
          });
          setCountStep(0);
          // window.location.href=`http://localhost:3000/thank-you?${queryString}`
          window.location.href = `https://thedesignverse.com/thank-you?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <div>
      <main className="content">
        <div className="heroSec lazy-background">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-7 col-md-6">
                  <div className="content">
                    <h4>Premiumly Crafted</h4>
                    <h2>
                      Custom Logo <span>Design Services</span>
                    </h2>
                    <p>
                      We shape your brand with innovative Logo Designs to give
                      your Brand a truly remarkable &amp; professional look.
                    </p>
                    <h4 className="bnnr-discount bnnr-discount">
                      SIGN UP NOW AND GET UP TO <span>70% OFF</span>
                    </h4>
                    <div className="main-form">
                      {/* <div className="row">
                        {" "}
                        <div class="col-sm-12">
                          <div class="form-group" style={{marginTop: "10px"}}>
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Enter Your Email"
                              name="email"
                              required=""
                            />
                          </div>
                        </div>
                        <div class="col-sm-12">
                                    <button type="submit" class="themeBtn" id="signupBtn">SUBMIT NOW</button>
                                </div>
                      </div>
                      <div class="col-sm-12">
                        <p style={{fontSize: "12px" }}>
                          {" "}
                          By clicking "Submit," you confirm that you agree to
                          Echo Logo
                          <a href="javscript:;"> Privacy Policy. </a>
                        </p>
                      </div> */}
                      <form className="form_submission" onSubmit={onSubmitFunc}>
                        <div className="frm-grup-cta">
                          {countstep === 0 ? (
                            <div
                              className="form-group side-form-group tab tab-1"
                              style={{ display: 'block' }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="Enter Your Name"
                                required
                                value={formData.name}
                                onChange={handleChange}
                              />
                            </div>
                          ) : countstep === 1 ? (
                            <div
                              className="form-group side-form-group tab tab-1"
                              style={{ display: 'block' }}
                            >
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Enter Your Email"
                                required
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </div>
                          ) : (
                            <div
                              className="form-group side-form-group tab tab-1"
                              style={{ display: 'block' }}
                            >
                              <input
                                type="number"
                                className="form-control"
                                name="phone"
                                placeholder="Enter Your Phone Number"
                                required
                                min={9}
                                minLength={9}
                                value={formData.phone}
                                onChange={handleChange}
                              />
                              {errors && (
                                <div className="error">
                                  Phone number must be at least 9 digits long
                                </div>
                              )}
                            </div>
                          )}

                          <div className="col-sm-12">
                            <p style={{ marginTop: 20, fontSize: 12 }}>
                              {' '}
                              By clicking "Submit," you confirm that you agree
                              to
                              {/*?php echo WEBSITE_NAME ?*/}{' '}
                              <a href="javscript:;"> Privacy Policy. </a>
                            </p>
                          </div>
                          <div className="frm-grup-cta-btn">
                            {/* <button class="themeBtn" id="prevBtn" onclick="nextPrev(-1)" style="display: none;" type="button">Prev</button>
                                 <button class="themeBtn" id="nextBtn" onclick="nextPrev(1)" style="display: inline;" type="button"> Next</button>
                                 <button class="learn-more-tab themeBtn" id="signupBtn2" name="signupForm" style="display: none;" type="submit">Submit</button> */}
                            {countstep > 0 && (
                              <button
                                className="themeBtn tab-1-btn prev"
                                type="button"
                                style={{ display: 'inline' }}
                                onClick={handlePrevClick}
                              >
                                Prev
                              </button>
                            )}
                            {countstep < 2 ? (
                              <button
                                className="themeBtn tab-1-btn next"
                                type="button"
                                style={{ display: 'inline' }}
                                onClick={handleNextClick}
                              >
                                Next
                              </button>
                            ) : loading === true ? (
                              <div
                                className="learn-more-tab themeBtn"
                                style={{
                                  display: 'inline-block',
                                  padding: '01.rem 0.5rem',
                                  margin: '0px 2px',
                                }}
                              >
                                <img src={logoGif} alt="gif" width={'35px'} />
                              </div>
                            ) : (
                              <button
                                className="learn-more-tab themeBtn"
                                id="signupBtn2"
                                name="signupForm"
                                type="submit"
                                style={{ display: 'inline' }}
                                disabled={loading}
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 bnnr-logos-col">
                  <div className="bannerslider banner_slider_img">
                    {/* <figure className="logo-wrap-disable">
                <img
                  src={bannerMain}
                  className="w-100 lazyload "
                  alt=""
                />
              </figure> */}
                    <figure className="logo-wrap-disable">
                      <img
                        src={bannerMain_02}
                        className="w-100 lazyload "
                        alt=""
                      />
                    </figure>
                    {/* <figure className="logo-wrap-disable">
                <img
                  src={bannerMain_03}
                  className="w-100 lazyload "
                  alt=""
                />
              </figure> */}
                  </div>
                  {/* <div className="banner-mob-img">
              <img
                src={bnnrMobImg}
                className="w-100 lazyload "
                alt=""
              />
            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
