import React from 'react'
import { portfolioImg, portfolioImg2, portfolioImg3, portfolioImg4, portfolioImg5, portfolioImg6, portfolioImg7, textcircle_01 } from '../../assest'

export const OurPortfolio = () => {
  return (
    <div>
      <section className="our-portfolio lazy-background">
  <div className="container">
    <div className="secHeading main-hdg">
      <h2>
        Our
        <span>  {" "}
          Portfolio
          <img
            src={textcircle_01}
            alt="textcircle_01"
            className="lazyload"
          />
        </span>
      </h2>
    </div>
    <div className="row portfolio-images1 no-gutters">
      <div className="col-md-4">
        <img
          src={portfolioImg}
          className="img-fluid  lazyload"
          alt="portfolioImg"
        />
      </div>
      <div className="col-md-8 pr-0">
        <img
          src={portfolioImg2}
          className="img-fluid  lazyload"
          alt=""
        />
      </div>
      <div className="col-md-4">
        <img
          src={portfolioImg3}
          className="img-fluid  lazyload"
          alt=""
        />
      </div>
      <div className="col-md-4">
        <img
          src={portfolioImg4}
          className="img-fluid  lazyload"
          alt=""
        />
      </div>
      <div className="col-md-4 pr-0">
        <img
          src={portfolioImg5}
          className="img-fluid  lazyload"
          alt=""
        />
      </div>
      <div className="col-md-8 ">
        <img
          src={portfolioImg6}
          className="img-fluid  lazyload"
          alt=""
        />
      </div>
      <div className="col-md-4 pr-0">
        <img
          src={portfolioImg7}
          className="img-fluid  lazyload"
          alt=""
        />
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

