const Admin_Panel = 'info@thedesignverse.com';
const PHONE = '(855) 535-9320';
const PHONE_HREF = 'tel:8555359320';
// const PHONE_TWO = '(855) 535-9320';
// const PHONE_TWO_HREF = 'tel:8555359320';
const EMAIL = 'info@thedesignverse.com';
const EMAIL_HREF = 'mailto:info@thedesignverse.com';
const CONTACT_EMAIL = 'info@thedesignverse.com';
const CONTACT_EMAIL_HREF = 'mailto:info@thedesignverse.com';
const ADDRESS = '701 E Cathedral Rd Ste 45 Philadelphia, PA 19128';
const WEBSITE_NAME = 'The Design Verse';
const SITE_CURRENCY_SYMBOLS = '$';
const WEBSITE_LOGO = 'https://www.thedesignverse.com/logo.gif';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  //   PHONE_TWO,
  //   PHONE_TWO_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
  WEBSITE_LOGO,
};
