import React, { useEffect, useState } from 'react';
import { Header } from '../../../layout/header';
import { Footer } from '../../../layout/footer';
import { WEBSITE_NAME, PHONE, PHONE_HREF, EMAIL_HREF, EMAIL } from '../../../config';

export const Terms = () => {
  return (
    <div>
      <Header />
      <section class="main-privacy">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 privacy">
                    <div class="text-center">
                        <h4>Terms & Conditions</h4>
                        <p>The following terms and conditions apply to projects undertaken by
                            {WEBSITE_NAME}
                        </p>
                    </div>
                    <div class="term-text">
                        <h3>Revision Policy</h3>
                        <p>Revisions are contingent on the package selected. Clients can approach us for unlimited free
                            amendments, and we will revise their design with no extra charges, given that the design and
                            concept remain intact. The revision turnaround time will be 48 hours.
                        </p>
                        <h3>Refund Policy</h3>
                        <p>{WEBSITE_NAME} allows you to have your money back within 45 days of
                            order placement & reserves the right to terminate this, at any time, with or without notice
                            depending upon the scenario. This offer strictly implies that the refund is asked upon
                            viewing
                            the initial samples only; requesting revisions on the initial design work shall be regarded
                            as a
                            continuation of the project & will make this guarantee annulled. We rely on the customer’s
                            prompt feedback during the design process to complete the task effectively and therefore
                            hope
                            that the customer gives proper feedback to get the desired results.
                        </p>
                        <p>We won’t entertain or refund any orders if the client stays non-responsive for 45 Days;
                            however,
                            the client can email us to hold the project if there is some personal issue.
                        </p>
                        <p>If the client wants to forfeit any part of the discounted package, the refund is not
                            applicable.
                            For example; if the client has availed the discounted package for a Logo & Website and only
                            wants to avail one service instead of both, he/she will not be applicable for any
                            refund.
                        </p>
                        <p>Furthermore, the customer shall forfeit the right to the refund outlined above if the
                            customer
                            request additional revisions (1 or more design changes regardless of the complexity) or
                            modifications to any of the initial concepts. You shall also forfeit the right to a refund
                            if
                            you do not respond promptly to a status notification from
                            "{WEBSITE_NAME} ".
                        </p>
                        <p>In case of finalizing one part of the order, the customer won’t be eligible for a refund for
                            the
                            remaining package.
                        </p>
                        <p>24-hour design fees and 24-hour rush changes are non-refundable.</p>
                        <p>No refund is available for design firms or those who order our design services on behalf of
                            another entity. If an order cancellation request is posted before the delivery of the
                            initial
                            concepts, you are eligible for a refund. In case of a duplicate charge, the 100% amount will
                            be
                            returned. If design requirements are not fulfilled, committed at the time of sale, or the
                            designs are not delivered according to the delivery policy, though a proof is required for
                            such
                            commitment, a refund can be claimed. You agree for bundle packages (e.g., Logo, Stationery,
                            Website, Social Media, and Brochure), the refund can't be claimed if there is any
                            discontinuation with a certain service included in the bundle package once any of the
                            service is
                            availed.
                        </p>
                        <p><strong> The following terms and conditions apply to all the services purchased by the client
                                and
                                provided by {WEBSITE_NAME} .</strong>
                        </p>
                        <p>On any occasion, any funds deposited will not be liable for a refund if the initial design
                            and
                            concepts (after delivery) are approved, or a change is asked for unless
                            {WEBSITE_NAME} cancels or ends your Contract for a reason other
                            than
                            your breach or non-execution.
                        </p>
                        <p>All refund requests will be as per the following arrangement:</p>
                        <p>You make a solicitation when the underlying ideas for a logo are provided. However, once you
                            approve or ask for changes in the initial designs, the refund offer will be void, and a
                            refund
                            request will not be entertained.
                        </p>
                        <p>Once the project has been entered into the revision phase,&nbsp;the refund offer will be
                            void,
                            and the refund request will not be entertained.
                        </p>
                        <p>On the off chance that demand for a refund is made before the delivery of initial design
                            concepts, then you would qualify for a full refund (less 10% administration and preparing
                            charge).
                        </p>
                        <p>If the refund request is made within 48 hours, you will only be eligible for a 66% refund
                            (less
                            10% administration and preparing expense).
                        </p>
                        <p>If the refund request is made within 48-96 hours after the initial design delivery, you will
                            only
                            be eligible for a 33% refund (less 10% service &amp; processing fee).
                        </p>
                        <p>No refund request will be entertained after 96&nbsp;hours of the initial design delivery.
                            However, we believe in 100% customer satisfaction, so you are requested to reach out to us
                            to
                            address your concerns.
                        </p>
                        <p>No request for refund will be entertained after inaction by the customer after 7
                            working&nbsp;days. If you wish to restart the order, you will be charged a certain fee
                            contingent upon your venture.
                        </p>
                        <p>No request for a refund will be entertained if the customer goes unresponsive at any point in
                            time for 5 working days.
                        </p>
                        <p>All refund requests must be communicated to the support department.
                            {WEBSITE_NAME} reserves the right to approve/reject any refund requests
                            based on an individual case-to-case premise.
                        </p>
                        <p>No refund will be entertained after the final files have been delivered.</p>
                        <p>For Website bundles, no refund will be entertained once the initial design mock-up has been
                            revised or the inner pages created with the client’s approval.
                        </p>
                        <p>All refund requests must be communicated to the support department.
                            {WEBSITE_NAME}, in light of the infringement of your user agreement,
                            reserves all authority to affirm/object to your solicitation on an individual case to case
                            premise.
                        </p>
                        <p>For {WEBSITE_NAME} /Custom bundles, a refund will be applied just
                            as
                            on the single packages.
                        </p>
                        <p>For instance, if you request a logo and web design and approve of the logo, you are eligible
                            for
                            the refund of the website service at the time of initial design only.
                        </p>
                        <p>A refund request should have a legitimate reason which must be qualified against the outline
                            brief and client input for revisions. Unless an idea has not been composed as per the brief,
                            a
                            refund will not be approved; however, a discount won’t be given. However, further revisions
                            will
                            be provided until you are satisfied.
                        </p>
                        <p>It is also to be noted that, under any circumstances, both parties
                            ({WEBSITE_NAME} &amp; Client) agree not to attack/criticize each
                            other and any of its employees, associate/s or partner/s publicly (on public forums, blogs,
                            social networks, etc.) at any given time during or after the contract period. Similarly,
                            both
                            parties agree not to talk on forums, blogs, community groups, or any social media platform
                            in a
                            way that brings a bad name to either party or any of its employees, associate, or partner.
                            In
                            case of a breach, the breaching party would have to pay a reasonable compensation decided by
                            the
                            non-breaching party as damages.
                        </p>
                        <p>Money-Back Guarantee depends on that the request is set per some basic honesty. Where a
                            client
                            has placed design orders with more than one agency for the same job, intending to claim a
                            refund, {WEBSITE_NAME} does not consider this to be good faith. In
                            such instances, we reserve the right to decline a refund request.
                        </p>
                        <p>All design jobs require client input before finishing the design, so it is requested that the
                            customer be active throughout the process and give feedback to get the required results.
                        </p>
                        <p>100% unique design guarantee qualifies you for a new logo if the logo designed by
                            {WEBSITE_NAME} is found to be considerably similar to another
                            design that already exists. Any likeness to a current outline will be just a fortuitous
                            event,
                            and {WEBSITE_NAME} won’t acknowledge any responsibility or claim
                            of
                            any compensation in such a case. After the finalization of the logo it needs to go through a
                            registration process that will be done through the agency, {WEBSITE_NAME} (USPTO
                            charges
                            applied), in order to have complete ownership and 100% rights over the logo.
                        </p>
                        <p>Change of mind is not applicable for refund.</p>
                        <h3>How To Claim Your Refund</h3>
                        <p>To ensure that your refund request is processed effectively and is approved, please make sure
                            that you meet the following requirements.
                        </p>
                        <p>1. Specify your concern and claim your refund through any of the following three modes:-</p>
                        <p>• i. Toll free # <a href={PHONE_HREF}>{PHONE}</a> </p>
                        <p>• ii. <a href="javascript:;" class="chat">Live Chat.</a></p>
                        <p>• iii. Email <a href={EMAIL_HREF}>({EMAIL})</a></p>
                        <p>We will try to resolve your query and concern in light of our revision policy immediately or
                            email you a refund request approval from our refund department.
                        </p>
                        <p>After the refund, the rights to your design would be transferred to
                            {WEBSITE_NAME} and you would not legally be allowed to display any
                            version of the design sent by the company.
                        </p>
                        <p>1. Since the design rights would now be transferred to
                            {WEBSITE_NAME}, you concur that you will have no right (immediate
                            or circuitous) to use any reaction or other substance, work item, or media, nor will you
                            have
                            any ownership interest for or to the same.
                        </p>
                        <p>2. Working in a joint effort with the Government Copyright Agencies
                            {WEBSITE_NAME} would share Copyright Acquisition data for the
                            refunded outlines that would confine the re-utilization of the designs as original designs
                            in
                            the future.
                        </p>
                        <p>If you have any questions or concerns about our Refund Policy, please contact us by clicking
                            here
                            <a href={EMAIL_HREF}>({EMAIL})</a>.
                        </p>
                        <h3>My Account</h3>
                        <p>The My Account area is a convenient way to communicate. It is your sole responsibility to
                            check
                            the account section for all questions, queries, concerns, and any other instructions for the
                            designer. Failure to actively check my account section may become a cause of hindrance in
                            your
                            perusal of a refund. If you are unsure of how to use the My Account area, please get in
                            touch
                            with customer support at any time for prompt assistance.
                        </p>
                        <h3>Quality Assurance Policy</h3>
                        <p>For complete satisfaction, our designers are instructed not to deviate from the
                            specifications
                            provided by the client in the order form.
                        </p>
                        <p>The designs are crafted after adequate and thorough research to ensure quality and
                            uniqueness.
                        </p>
                        <h3>100% Satisfaction Guarantee</h3>
                        <p>We revamp the requested design and continue overhauling it until you are 100% fulfilled
                            (depending upon your package).
                        </p>
                        <h3>Domain and Hosting</h3>
                        <p>Domain and Hosting are provided if included in your customized package.</p>
                        <p>Emails can only be provided if your website is hosted on our server.</p>
                        <p>If you wish to opt-out of hosting the website with us, you will not be provided with email
                            accounts.
                        </p>
                        <h3>Delivery Policy</h3>
                        <p>All design order files are delivered to My Account as specified on the “Order Confirmation”
                            date.
                            An email is also sent to inform the client about their design order delivery to their
                            specific
                            account area. All policies about revision & refund are subject to the date and time of the
                            design order delivered to the client’s account area.
                        </p>
                        <p>All design order files are delivered to “My Account” as per the date specified on the “Order
                            Confirmation.” An email is also sent to inform the client about their design order delivery
                            to
                            their specific account area. All policies about revision & refund are subject to the date
                            and
                            time of the design order delivered to the client’s account area.
                        </p>
                        <p>All customized design orders are delivered via email within 5 to 7 days after receipt of the
                            order.
                        </p>
                        <p>We offer a RUSH DELIVERY service through which you can have your first logo within
                            24&nbsp;hours
                            by paying just $100 additional! For further help, get in touch with our customer support
                            department.
                        </p>
                        <p>The sole purpose of the test servers is to design and develop the front end of the website
                            initially. In addition, server testing allows our development team to identify and fix
                            flaws, such as downtime or run-time errors. The duration of the test server is 2 months from
                            the original date of purchase.
                        </p>
                        <h3>Record Maintenance</h3>
                        <p>We keep your final design archived after we deliver your final files. If you wish to receive
                            the
                            final files again, we can email them upon request.
                        </p>
                        <h3>Customer Support</h3>
                        <p>We offer 24-Hour Customer Support to address your questions and queries.</p>
                        <p>You can get in touch with us at whatever time, and we promise a prompt reply.</p>
                        <p>Correspondence Policy</p>
                        <p>YOU concur that {WEBSITE_NAME} is not at risk for any
                            correspondence
                            from email address (es) other than the ones taken after by our particular area, i.e.,
                            <a href={EMAIL_HREF}>"{EMAIL}"</a> or/and any toll-free number
                            that is not specified on
                            our site. {WEBSITE_NAME} ought not to be considered in charge of
                            any damage(s) brought about by such correspondence. We assume the liability of any
                            correspondence through email address (es) under our space name or/and through the toll-free
                            number, i.e., as specified on {WEBSITE_NAME} Website.
                        </p>
                        <p>Money-Back Guarantee</p>
                        <p>We are extremely confident of the work we do, which is why we back it up with a money-back
                            guarantee. If we are unable to meet and beat your expectations, we’ll give you a refund.
                        </p>
                        <p>100% Unique Design Guarantee</p>
                        <p>At {WEBSITE_NAME} we promise that all of our logos are
                            produced/designed from scratch. We will provide you a logo that is adept and in complete
                            compliance with your design brief.
                        </p>
                        <h3>Email & Mobile Phone Policy</h3>
                        <p>This policy outlines the terms and conditions regarding the use of email and mobile phone
                            contact information provided to {WEBSITE_NAME}.</p>
                        <h3>1. Consent and Usage</h3>
                        <p>By providing your email address and/or mobile phone number, you consent to receive
                            communication from {WEBSITE_NAME}. This includes, transitional messages,
                            periodic updates about
                            your service or project, updates, newsletters, and services delivery e.g. Design
                            attachments, Project updates and Revisions upsdate.</p>
                        <h3>2. Carrier Charges Disclosure</h3>
                        <p>Standard messaging and data rates may apply. Please consult your mobile service carrier for
                            details.</p>
                        <h3>3. Stop & Unsubscribe</h3>
                        <p>If you wish to stop receiving emails or mobile communications, you can:</p>
                        <ul>
                            <li>For emails, click the "unsubscribe" link located at the bottom of any email you receive
                                from us.</li>
                            <li>For mobile communications, reply "STOP" or “Unsub” “Unsubcribe” to any SMS message you
                                receive from us. The SMS will will be stopped right away.</li>
                            <h3>4. Frequency of Communication</h3>
                            <p>We will strive to limit the frequency of our communications to a reasonable level.
                                However, there may be exceptions during special update about project or urgent
                                notifications.</p>
                            <h3>5. Security of Information</h3>
                            <p>Your email address and mobile phone number will be kept confidential and will not be
                                shared with third parties without your explicit consent, except as required by law.</p>
                            <h3>6. Changes to Policy</h3>
                            <p>{WEBSITE_NAME} reserves the right to modify this policy at any time. Any
                                changes will be posted on our website, and it is your responsibility to review these
                                changes.</p>
                            <h3>7. Contact Us</h3>
                            <p>If you have any questions or concerns regarding this policy, please contact us at <a
                                    href={EMAIL_HREF}>{EMAIL}</a> </p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
      <Footer />
    </div>
  );
};
