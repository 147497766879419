import React from 'react'
import { logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8 } from '../../assest'

export const PortfolioComp = () => {
  return (
    <div>
      <section className="logosSec">
  <div className="marquee-wrap">
    <div className="marquee-grad" />
    <div className="marquee-horizontal-alt-css w-embed"></div>
    <div className="track-horizontal">
      <div className="logos">
        <figure className="marquee-logo-wrap">
          <img
            src={logo1}
            loading="eager"
            alt="Client logo: NHS"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo2}
            loading="eager"
            alt="Client logo: GfK"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo3}
            loading="eager"
            alt="Client logo: HM Gov"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo4}
            loading="eager"
            alt="Client logo: AllianceBernstein"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo5}
            loading="eager"
            alt="Client logo: Visa"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo6}
            loading="eager"
            alt="JLL logo"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
           src={logo7}
            loading="eager"
            alt="Client logo: GSK"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo8}
            loading="eager"
            alt="Logo for finance client, EMVCo"
            className="marquee-logo lazyload"
          />
        </figure>
      </div>
      <div className="logos">
        <figure className="marquee-logo-wrap">
          <img
            src={logo1}
            loading="eager"
            alt="Client logo: NHS"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo2}
            loading="eager"
            alt="Client logo: GfK"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo3}
            loading="eager"
            alt="Client logo: HM Gov"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo4}
            loading="eager"
            alt="Client logo: AllianceBernstein"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo5}
            loading="eager"
            alt="Client logo: Visa"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo6}
            loading="eager"
            alt="JLL logo"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
           src={logo7}
            loading="eager"
            alt="Client logo: GSK"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src={logo8}
            loading="eager"
            alt="Logo for finance client, EMVCo"
            className="marquee-logo lazyload"
          />
        </figure>
      </div>
      {/* <div className="logos">
        <figure className="marquee-logo-wrap">
          <img
            src="assets/images/webp/logo1.webp"
            loading="eager"
            alt="Client logo: NHS"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src="assets/images/webp/logo2.webp"
            loading="eager"
            alt="Client logo: GfK"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src="assets/images/webp/logo3.webp"
            loading="eager"
            alt="Client logo: HM Gov"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src="assets/images/webp/logo4.webp"
            loading="eager"
            alt="Client logo: AllianceBernstein"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src="assets/images/webp/logo5.webp"
            loading="eager"
            alt="Client logo: Visa"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src="assets/images/webp/logo6.webp"
            loading="eager"
            alt="JLL logo"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src="assets/images/webp/logo7.webp"
            loading="eager"
            alt="Client logo: GSK"
            className="marquee-logo lazyload"
          />
        </figure>
        <figure className="marquee-logo-wrap">
          <img
            src="assets/images/webp/logo8.webp"
            loading="eager"
            alt="Logo for finance client, EMVCo"
            className="marquee-logo lazyload"
          />
        </figure>
      </div> */}
    </div>
  </div>
</section>

    </div>
  )
}

