import React, { useEffect } from 'react';
import { logo } from '../../assest';
import { NavLink } from 'react-router-dom';
import { PHONE, PHONE_HREF, WEBSITE_LOGO } from '../../config';

export const Header = () => {
  useEffect(() => {
    // Define the function to toggle the chat

    const toggleChat = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.toggle();
      }
    };

    // Attach event listeners when the component mounts
    const chatButton = document.querySelector('.zendeskchat'); // Replace '.chat' with the appropriate button selector
    if (chatButton) {
      chatButton.addEventListener('click', toggleChat);
    }

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      if (chatButton) {
        chatButton.removeEventListener('click', toggleChat);
      }
    };
  }, []);

  useEffect(() => {
    // Ensure $zopim is available globally
    window.$zopim =
      window.$zopim ||
      function (c) {
        (window.$zopim.q = window.$zopim.q || []).push(c);
      };

    // Wait for the zopim live chat API to be ready
    window.$zopim(() => {
      // Define the function to show chat window when there are unread messages
      window.$zopim.livechat.setOnUnreadMsgs((numUnread) => {
        if (numUnread > 0 && !window.$zopim.livechat.window.getDisplay()) {
          window.$zopim.livechat.window.show();
        }
      });
    });
  }, []);

  return (
    <div>
      <header>
        <nav>
          <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-lg-4 col-md-4 col-5">
                <NavLink to="/" class="navbar-brand">
                  <img class="lazyload logo_img" src={logo} alt="logo" />
                </NavLink>
              </div>
              <div class="col-lg-6 col-md-8 col-7">
                <ul>
                  <li>
                    <a href={PHONE_HREF}>
                      <i class="fas fa-phone-alt"></i>
                      <span>Call Us:</span> {PHONE}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" class="themeBtn chat zendeskchat">
                      Talk To Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};
