import React, { useState } from "react";
import { pec1, pec2, pec3, pec4, pecBor, textcircle_01 } from "../../assest";
import { ModalSection } from "../../layout/modalSection";
import Slider from "react-slick";

export const OurPricing = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [open, setOpen] = useState(false);

  const [activeState, setActiveState] = useState(0);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const onTabFunc = (id) => {
    setActiveState(id);
  };

  return (
    <div>
      <section className="our-pricing">
        <div className="container">
          <div className="secHeading main-hdg">
            <h2>
              Our{" "}
              <span>
                {" "}
                Pricing
                <img
                  className="lazyload"
                  src={textcircle_01}
                  alt="textcircle_01"
                />
              </span>
            </h2>
          </div>
          <div className="price_tabs">
            <button className={`themeBtn ${activeState ==  0 ? "" : "active_tab"}`} onClick={() => onTabFunc(0)}>
              Logo
            </button>
            <button className={`themeBtn ${activeState ==  1 ? "" : "active_tab"}`} onClick={() => onTabFunc(1)}>
              Website
            </button>
          </div>
          <div className="pricing_carosaul mt-5">
            {activeState == 0 ? (
              <Slider {...settings} className="">
                <div className="col-md-4">
                  <div className="pec-box lazy-background pec-before packagebox">
                    <div className="img-new">
                      <img src={pec1} className="lazyload" />
                    </div>
                    <div className="pec-name">
                      <h1 className="pack-title">Logo Basic</h1>
                      <img src={pecBor} className="lazyload" />
                    </div>
                    <div className="pec-list pkg_list">
                      <ul>
                        <li>4 Original Logo Concepts</li>
                        <li> 2 Dedicated Logo Designer</li>
                        <li> 4 Revisions</li>
                        <li> With Grey Scale Format</li>
                        <li> Free Icon Design</li>
                        <li> Formats: JPEG Only</li>
                        <li> 24 - 48 Hours Turn Around Time</li>
                        <li className="features">MORE FEATURES</li>
                        <li> 100% Satisfaction</li>
                        <li> 100% Ownership Rights</li>
                        <li> Money Back Guarantee</li>
                        <li> Dedicated Account Manager</li>
                      </ul>
                    </div>
                    <img src={pecBor} className="lazyload" />
                    <div className="price">
                      <h3 className="pac-price">$35.00</h3>
                      <h2>
                        Was<sub>$117.00</sub>
                      </h2>
                    </div>
                    <a
                      href="javascript:;"
                      onClick={onOpenModal}
                      className="themeBtn pkg_btn openpopup"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pec-box lazy-background pec-before packagebox">
                    <div className="img-new">
                      <img className="lazyload" src={pec4} />
                    </div>
                    <div className="pec-name">
                      <h1 className="pack-title">Logo Startup</h1>
                      <img className="lazyload" src={pecBor} />
                    </div>
                    <div className="pec-list pkg_list">
                      <ul>
                        <li>6 Logo Design Concepts</li>
                        <li>4 Revisions</li>
                        <li>2 Dedicated Designers</li>
                        <li> Free Color Options</li>
                        <li>
                        Free Grayscale Format
                        </li>
                        <li>Free Icon</li>
                        <li>24 To 48 Hours TAT</li>
                        <li>Print Friendly Design</li>
                        <li>Free Icon Design</li>
                        <li>JPEG, PNG and PDF</li>
                      </ul>
                    </div>
                    <img className="lazyload" src={pecBor} />
                    <div className="price">
                      <h3 className="pac-price">$75.00</h3>
                      <h2>
                        Was<sub>$250.00</sub>
                      </h2>
                    </div>
                    <a
                      href="javascript:;"
                      className="themeBtn pkg_btn openpopup"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pec-box lazy-background pec-before packagebox">
                    <div className="img-new">
                      <img src={pec2} className="lazyload" />
                    </div>
                    <div className="pec-name">
                      <h1 className="pack-title">Logo Professional</h1>
                      <img src={pecBor} className="lazyload" />
                    </div>
                    <div className="pec-list pkg_list">
                      <ul>
                        <li>12 Original Logo Concepts</li>
                        <li>4 Dedicated Logo Designer (Industry Specific)</li>
                        <li>Unlimited Revisions</li>
                        <li>
                          Stationery Design (Business Card, Letterhead,
                          Envelope)
                        </li>
                        <li>Email Signature Design</li>
                        <li>With Grey Scale Format</li>
                        <li>Free Icon Design</li>
                        <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                        <li>24 - 48 Hours Turn Around Time</li>
                        <li className="features">MORE FEATURES</li>
                        <li>100% Satisfaction</li>
                        <li>100% Ownership Rights</li>
                        <li>Money Back Guarantee</li>
                        <li>Dedicated Account Manager</li>
                      </ul>
                    </div>
                    <img className="lazyload" src={pecBor} />
                    <div className="price">
                      <h3 className="pac-price">$119.00</h3>
                      <h2>
                        Was<sub>$397.00</sub>
                      </h2>
                    </div>
                    <a
                      href="javascript:;"
                      onClick={onOpenModal}
                      className="themeBtn pkg_btn openpopup"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pec-box lazy-background pec-before packagebox">
                    <div className="img-new">
                      <img className="lazyload" src={pec3} />
                    </div>
                    <div className="pec-name">
                      <h1 className="pack-title">Logo Elite</h1>
                      <img className="lazyload" src={pecBor} />
                    </div>
                    <div className="pec-list pkg_list">
                      <ul>
                        <li>Unlimited Original Logo Concepts</li>
                        <li>8 Dedicated Logo Designer (Industry Specific)</li>
                        <li>Unlimited Revisions</li>
                        <li>
                          Stationery Design (Business Card, Letterhead,
                          Envelope)
                        </li>
                        <li>500 Business Cards </li>
                        <li>Email Signature Design</li>
                        <li>With Grey Scale Format</li>
                        <li>Free Icon Design</li>
                        <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                        <li>24 - 48 Hours Turn Around Time</li>
                        <li className="features">MORE FEATURES</li>
                        <li>100% Satisfaction</li>
                        <li>Money Back Guarantee</li>
                        <li>Dedicated Account Manager</li>
                      </ul>
                    </div>
                    <img className="lazyload" src={pecBor} />
                    <div className="price">
                      <h3 className="pac-price">$299.00</h3>
                      <h2>
                        Was<sub>$849.00</sub>
                      </h2>
                    </div>
                    <a
                      href="javascript:;"
                      onClick={onOpenModal}
                      className="themeBtn pkg_btn openpopup"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
                
              </Slider>
            ) : (
              <Slider {...settings}>
                <div className="col-md-4">
                  <div className="pec-box lazy-background pec-before packagebox">
                    <div className="img-new">
                      <img src={pec1} className="lazyload" />
                    </div>
                    <div className="pec-name">
                      <h1 className="pack-title" style={{fontSize: "28px"}}>Basic Website </h1>
                      <img src={pecBor} className="lazyload" />
                    </div>
                    <div className="pec-list pkg_list">
                      <ul>
                        <li>3 Page Website</li>

                        <li> Contact/Query Form</li>
                        <li>1 Banner Design</li>
                        <li>2 Stock Photos</li>
                        <li>FREE Favicon Design</li>
                        <li>Cross Browser Compatible</li>
                        <li> Complete W3C Certified HTML</li>
                        <li>Custom Layout Design</li>
                        <li>Website Initial Concepts in 48 Hours</li>
                        <li>Complete Design & Deployment</li>
                        <li>Complete Source Files</li>
                        <li>Dedicated Project Manager</li>
                        <li>100% Ownership Rights</li>
                        <li>100% Satisfaction Guarantee</li>
                        <li>100% Money Back Guarantee</li>
                        <li>Value Added Services</li>
                        <li>Mobile Responsive will be Additional $200*</li>
                        <li>CMS will be Additional $250*</li>
                        <li> *NO MONTHLY OR ANY HIDDEN FEE*</li>
                        {/* <li> 2 Dedicated Logo Designer</li>
                <li> 4 Revisions</li>
                <li> With Grey Scale Format</li>
                <li> Free Icon Design</li>
                <li> Formats: JPEG Only</li>
                <li> 24 - 48 Hours Turn Around Time</li>
                <li className="features">MORE FEATURES</li>
                <li> 100% Satisfaction</li>
                <li> 100% Ownership Rights</li>
                <li> Money Back Guarantee</li>
                <li> Dedicated Account Manager</li> */}
                      </ul>
                    </div>
                    <img src={pecBor} className="lazyload" />
                    <div className="price">
                      <h3 className="pac-price">$499.00</h3>
                      <h2>
                        Was<sub>$117.00</sub>
                      </h2>
                    </div>
                    <a
                      href="javascript:;"
                      onClick={onOpenModal}
                      className="themeBtn pkg_btn openpopup"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pec-box lazy-background pec-before packagebox">
                    <div className="img-new">
                      <img src={pec2} className="lazyload" />
                    </div>
                    <div className="pec-name">
                      <h1 className="pack-title" style={{fontSize: "28px"}}>Startup Website</h1>
                      <img src={pecBor} className="lazyload" />
                    </div>
                    <div className="pec-list pkg_list">
                      <ul>
                        <li>Contact/Query Form</li>
                        <li>3 Banner Designs</li>
                        <li>5 Stock Photos</li>
                        <li>FREE Favicon Design</li>
                        <li>FREE Google Friendly Sitemap</li>
                        <li>Unlimited Revisions</li>
                        <li>Complete W3C Certified HTML</li>
                        <li>Website Initial Concepts in 48 Hours</li>
                        <li>Complete Design & Deployment</li>
                        <li>Complete Source Files</li>
                        <li>Dedicated Project Manager</li>
                        <li>100% Ownership Rights</li>
                        <li>100% Satisfaction Guarantee</li>
                        <li>100% Money Back Guarantee</li>
                        <li>Value Added Services</li>
                        <li>Mobile Responsive will be Additional $200*</li>
                        <li>CMS will be Additional $250*</li>
                        <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                      </ul>
                    </div>
                    <img className="lazyload" src={pecBor} />
                    <div className="price">
                      <h3 className="pac-price">$899.00</h3>
                      <h2>
                        Was<sub>$397.00</sub>
                      </h2>
                    </div>
                    <a
                      href="javascript:;"
                      onClick={onOpenModal}
                      className="themeBtn pkg_btn openpopup"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pec-box lazy-background pec-before packagebox">
                    <div className="img-new">
                      <img className="lazyload" src={pec3} />
                    </div>
                    <div className="pec-name">
                      <h1 className="pack-title" style={{fontSize: "26px"}}>Professional Website</h1>
                      <img className="lazyload" src={pecBor} />
                    </div>
                    <div className="pec-list pkg_list">
                      <ul>
                        <li>Up to 10 Unique Pages Website</li>
                        <li>CMS /Admin Panel Integration</li>
                        <li>5+ Stock Photos & Banner Designs</li>
                        <li>FREE Social Media Integration</li>
                        <li>FREE Favicon Design</li>
                        <li>FREE Google Friendly Sitemap</li>
                        <li>Unlimited Revisions</li>
                        <li>Cross Browser Compatible</li>
                        <li>Complete W3C Certified HTML</li>
                        <li>Website Initial Concepts in 48 Hours</li>
                        <li>Complete Design & Deployment</li>
                        <li>Custom, Interactive & Dynamic Web Design</li>
                        <li>
                          Industry specified Team of Expert Designers and
                          Developers
                        </li>
                        <li>Complete Source Files</li>
                        <li>Dedicated Project Manager</li>
                        <li>100% Ownership Rights</li>
                        <li>100% Satisfaction Guarantee</li>
                        <li>100% Money Back Guarantee</li>
                        <li>Value Added Services</li>
                        <li>Mobile Responsive will be Additional $200*</li>
                        <li>CMS will be Additional $250*</li>
                        <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                      </ul>
                    </div>
                    <img className="lazyload" src={pecBor} />
                    <div className="price">
                      <h3 className="pac-price">$1499.00</h3>
                      <h2>
                        Was<sub>$849.00</sub>
                      </h2>
                    </div>
                    <a
                      href="javascript:;"
                      onClick={onOpenModal}
                      className="themeBtn pkg_btn openpopup"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pec-box lazy-background pec-before packagebox">
                    <div className="img-new">
                      <img className="lazyload" src={pec4} />
                    </div>
                    <div className="pec-name">
                      <h1 className="pack-title" style={{fontSize: "26px"}}>E-Commerce Website</h1>
                      <img className="lazyload" src={pecBor} />
                    </div>
                    <div className="pec-list pkg_list">
                      <ul>
                        <li>Custom Ecommerce Website</li>
                        <li>Up to 200 Products</li>
                        <li>CMS /Admin Panel Integration</li>
                        <li>Fully Mobile Responsive</li>
                        <li>Shopping Cart Integration</li>
                        <li>Payment Gateway Integration</li>
                        <li>Product Listing & Management</li>
                        <li>Order Management & Tracking</li>
                        <li>Banner Designs</li>
                        <li>Unlimited Revisions</li>
                        <li>FREE Social Media Integration</li>
                        <li>FREE Favicon Design</li>
                        <li>
                          FREE Google Friendly Sitemap Search Engine Submission
                        </li>
                        <li>Complete W3C Certified HTML</li>
                        <li>
                          Industry specified Team of Expert Designers and
                          Developers
                        </li>
                        <li>Complete Deployment</li>
                        <li>Complete Source Files</li>
                        <li>Dedicated Project Manager</li>
                        <li>100% Ownership Rights</li>
                        <li>100% Satisfaction Guarantee</li>
                        <li>100% Money Back Guarantee</li>
                        <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                      </ul>
                    </div>
                    <img className="lazyload" src={pecBor} />
                    <div className="price">
                      <h3 className="pac-price">$1799.00</h3>
                      <h2>
                        Was<sub>$1996.00</sub>
                      </h2>
                    </div>
                    <a
                      href="javascript:;"
                      onClick={onOpenModal}
                      className="themeBtn pkg_btn openpopup"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pec-box lazy-background pec-before packagebox">
                    <div className="img-new">
                      <img className="lazyload" src={pec4} />
                    </div>
                    <div className="pec-name">
                      <h1 className="pack-title" style={{fontSize: "28px"}}>Corporate Website</h1>
                      <img className="lazyload" src={pecBor} />
                    </div>
                    <div className="pec-list pkg_list">
                      <ul>
                        <li>
                          Online Signup Area (For Newsletters, Offers etc.)
                        </li>
                        <li>Search Bar</li>
                        <li>
                          Live Feeds of Social Networks integration (Optional)
                        </li>
                        <li>Google Friendly Sitemap</li>
                        <li>Search Engine Submission</li>
                        <li>Cross Browser Compatible</li>
                        <li>Complete W3C Certified HTML</li>
                        <li>
                          Industry specified Team of Expert Designers and
                          Developers
                        </li>
                        <li>Complete Deployment</li>
                        <li>Complete Source Files</li>
                        <li>Dedicated Project Manager</li>
                        <li>100% Ownership Rights</li>
                        <li>100% Satisfaction Guarantee</li>
                        <li>100% Money Back Guarantee</li>
                        <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                      </ul>
                    </div>
                    <img className="lazyload" src={pecBor} />
                    <div className="price">
                      <h3 className="pac-price">$3999.00</h3>
                      <h2>
                        Was<sub>$1996.00</sub>
                      </h2>
                    </div>
                    <a
                      href="javascript:;"
                      onClick={onOpenModal}
                      className="themeBtn pkg_btn openpopup"
                    >
                      Order Now
                    </a>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pec-box lazy-background pec-before packagebox">
                    <div className="img-new">
                      <img className="lazyload" src={pec4} />
                    </div>
                    <div className="pec-name">
                      <h1 className="pack-title" style={{fontSize: "28px"}}>Platinum Website</h1>
                      <img className="lazyload" src={pecBor} />
                    </div>
                    <div className="pec-list pkg_list">
                      <ul>
                        <li>Up to 20 Unique Pages Website</li>
                        <li>
                          Custom Made, Interactive, Dynamic & High-End Design
                        </li>
                        <li>Customized WordPress or PHP Development</li>
                        <li>Fully Mobile Responsive</li>
                        <li>Interactive Sliding Banners</li>
                        <li>Up to 15 Custom Made Banner Designs</li>
                        <li>15 Stock Images</li>
                        <li>Unlimited Revisions</li>
                        <li>Content Management System</li>
                        <li>
                          Online Appointment/Booking/Scheduling/Online Ordering
                          Integration (Optional)
                        </li>
                        <li>Online Payment Integration (Optional)</li>
                        <li>Multi Lingual (Optional)</li>
                        <li>Custom Dynamic Forms (Optional)</li>
                        <li>Signup Area (For Newsletters, Offers etc.)</li>
                        <li>Search Bar</li>
                        <li>
                          Live Feeds of Social Networks integration (Optional)
                        </li>
                        <li>Google Friendly Sitemap</li>
                        <li>Search Engine Submission</li>
                        <li>Cross Browser Compatible</li>
                        <li>Complete W3C Certified HTML</li>
                        <li>
                          Industry specified Team of Expert Designers and
                          Developers
                        </li>
                        <li>Complete Deployment</li>
                        <li>Complete Source Files</li>
                        <li>Dedicated Project Manager</li>
                        <li>100% Ownership Rights</li>
                        <li>100% Satisfaction Guarantee</li>
                        <li>100% Money Back Guarantee</li>
                        <li>*NO MONTHLY OR ANY HIDDEN FEE*</li>
                      </ul>
                    </div>
                    <img className="lazyload" src={pecBor} />
                    <div className="price">
                      <h3 className="pac-price">$6999.00</h3>
                      <h2>
                        Was<sub>$1996.00</sub>
                      </h2>
                    </div>
                    <a
                      href="javascript:;"
                      onClick={onOpenModal}
                      className="themeBtn pkg_btn openpopup"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
              </Slider>
            )}

            {/* <div className="col-md-4">
        <div className="pec-box lazy-background pec-before packagebox">
          <div className="img-new">
            <img className="lazyload" src={pec4} />
          </div>
          <div className="pec-name">
            <h1 className="pack-title">The Boss</h1>
            <img
              className="lazyload"
              src={pecBor}
            />
          </div>
          <div className="pec-list pkg_list">
            <ul>
              <li>3D/Mascot/Animated Logo</li>
              <li>Unlimited Original Logo Concepts</li>
              <li>8 Dedicated Logo Designer (Industry Specific)</li>
              <li> Unlimited Revisions</li>
              <li>Stationery Design (Business Card, Letterhead, Envelope)</li>
              <li>500 Business Cards</li>
              <li>Email Signature Design</li>
              <li>With Grey Scale Format</li>
              <li>Free Icon Design</li>
              <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
              <li>24 - 48 Hours Turn Around Time</li>
              <li className="features">MORE FEATURES</li>
              <li>100% Satisfaction</li>
              <li>Money Back Guarantee</li>
              <li>Dedicated Account Manager</li>
            </ul>
          </div>
          <img
            className="lazyload"
            src={pecBor}
          />
          <div className="price">
            <h3 className="pac-price">$599.00</h3>
            <h2>
              Was<sub>$1996.00</sub>
            </h2>
          </div>
          <a href="javascript:;" className="themeBtn pkg_btn openpopup">
            Order Now
          </a>
        </div>
      </div> */}
          </div>
        </div>
      </section>

      <ModalSection open={open} onCloseModal={onCloseModal} />
    </div>
  );
};
