import React, { useEffect, useState } from 'react';
import { Header } from '../../layout';
import { Footer } from '../../layout/footer';
import {
  Banner,
  BannerChat,
  ContactUs,
  HappyClient,
  HowItWorks,
  ImpressiveLogos,
  LogoInfinite,
  OurPortfolio,
  OurServices,
  PortfolioComp,
} from '../../module';
import { OurPricing } from '../../module/ourPricing';
import usePageMeta from '../../usePageTitle';
// import { Helmet } from 'react-helmet';

export const Home = () => {
  // const [title, setTitle] = useState('Custom Logo Design for Business by The Design Verse');

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await new Promise(resolve => {
  //       // setTimeout(() => resolve('Fetched Title'), 2000);
  //       resolve('Custom Logo Design for Business by The Design Verse')
  //     });
  //     setTitle(response);
  //   };

  //   fetchData();
  // }, []);
  usePageMeta();
  return (
    <div>
      {/* <Helmet>
        <title>{title}</title>
      </Helmet> */}
      <Header />
      <Banner />
      <PortfolioComp />
      <ImpressiveLogos />
      <OurServices />
      <BannerChat />
      <OurPortfolio />
      <OurPricing />
      <LogoInfinite />
      <HappyClient />
      <HowItWorks />
      <ContactUs />
      <Footer />
    </div>
  );
};
