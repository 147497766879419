import React from 'react'
import { textcircle_01 } from '../../assest'

export const  HowItWorks = () => {
  return (
    <div>
      <section className="how_works">
  <div className="container">
    <div className="secHeading main-hdg">
      <h2>
        How It
        <span> {" "}
          Works
          <img
            clas="lazyload"
            src={textcircle_01}
            alt="textcircle_01"
          />
        </span>
      </h2>
    </div>
    <div className="row">
      <div className="col-md-4">
        <div className="boxes">
          <div className="icons">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <defs />
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <path
                  d="M6,9 L6,15 C6,16.6568542 7.34314575,18 9,18 L15,18 L15,18.8181818 C15,20.2324881 14.2324881,21 12.8181818,21 L5.18181818,21 C3.76751186,21 3,20.2324881 3,18.8181818 L3,11.1818182 C3,9.76751186 3.76751186,9 5.18181818,9 L6,9 Z M17,16 L17,10 C17,8.34314575 15.6568542,7 14,7 L8,7 L8,6.18181818 C8,4.76751186 8.76751186,4 10.1818182,4 L17.8181818,4 C19.2324881,4 20,4.76751186 20,6.18181818 L20,13.8181818 C20,15.2324881 19.2324881,16 17.8181818,16 L17,16 Z"
                  fill="#000000"
                  fillRule="nonzero"
                />
                <path
                  d="M9.27272727,9 L13.7272727,9 C14.5522847,9 15,9.44771525 15,10.2727273 L15,14.7272727 C15,15.5522847 14.5522847,16 13.7272727,16 L9.27272727,16 C8.44771525,16 8,15.5522847 8,14.7272727 L8,10.2727273 C8,9.44771525 8.44771525,9 9.27272727,9 Z"
                  id="Rectangle-19-Copy-3"
                  fill="#000000"
                  opacity="0.3"
                />
              </g>
            </svg>
          </div>
          <h3>Details</h3>
          <p>
            Answer a few questions about your business, your industry and your
            audience and we’ll take it from there.
          </p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="boxes">
          <div className="icons">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <defs />
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <path
                  d="M12,10.9996338 C12.8356605,10.3719448 13.8743941,10 15,10 C17.7614237,10 20,12.2385763 20,15 C20,17.7614237 17.7614237,20 15,20 C13.8743941,20 12.8356605,19.6280552 12,19.0003662 C11.1643395,19.6280552 10.1256059,20 9,20 C6.23857625,20 4,17.7614237 4,15 C4,12.2385763 6.23857625,10 9,10 C10.1256059,10 11.1643395,10.3719448 12,10.9996338 Z M13.3336047,12.504354 C13.757474,13.2388026 14,14.0910788 14,15 C14,15.9088933 13.7574889,16.761145 13.3336438,17.4955783 C13.8188886,17.8206693 14.3938466,18 15,18 C16.6568542,18 18,16.6568542 18,15 C18,13.3431458 16.6568542,12 15,12 C14.3930587,12 13.8175971,12.18044 13.3336047,12.504354 Z"
                  fill="#000000"
                  fillRule="nonzero"
                  opacity="0.3"
                />
                <circle fill="#000000" cx={12} cy={9} r={5} />
              </g>
            </svg>
          </div>
          <h3>Design</h3>
          <p>
            Our design team gets involved and we start crafting unique logo
            options to reflect your business.
          </p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="boxes">
          <div className="icons">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <defs />
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <path
                  d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M13.8,4 C13.1562,4 12.4033,4.72985286 12,5.2 C11.5967,4.72985286 10.8438,4 10.2,4 C9.0604,4 8.4,4.88887193 8.4,6.02016349 C8.4,7.27338783 9.6,8.6 12,10 C14.4,8.6 15.6,7.3 15.6,6.1 C15.6,4.96870845 14.9396,4 13.8,4 Z"
                  fill="#000000"
                  opacity="0.3"
                />
                <path
                  d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z"
                  fill="#000000"
                />
              </g>
            </svg>
          </div>
          <h3>Delivery</h3>
          <p>
            As soon as your new logo designs are completed, we'll send them
            straight to your inbox.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

