import React, { useState } from 'react';
import './App.css';
import { Header } from './layout';
import { Footer } from './layout/footer';
import {
  Banner,
  BannerChat,
  ContactUs,
  HappyClient,
  HowItWorks,
  ImpressiveLogos,
  LogoInfinite,
  OurPortfolio,
  OurServices,
  PortfolioComp,
} from './module';
import { ThankYou } from './module/thankyou';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
// import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { OurPricing } from './module/ourPricing';
import { Home } from './layout/pages';
import usePageTitle from './usePageTitle';
import { Privacy } from './layout/pages/Privacy';
import { Terms } from './layout/pages/Terms';

// function App() {
//   return (
//     <div>
//       <Router>
//         <Routes>
//           <Route path="/" element={<Home />} exact/>
//           <Route path="/privacy-policy" element={<Privacy />} />
//           <Route path="/terms-and-conditions" element={<Terms />} />
//           <Route path="/thank-you" element={<ThankYou />} />
//         </Routes>
//       </Router>
//     </div>
//   );
// }
function App() {
  return (
    <div>
      <React.StrictMode>
        <BrowserRouter basename="/">
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/thank-you" element={<ThankYou />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </div>
  );
}
export default App;
